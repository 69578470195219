<template>
    <div class="card card-secondary card-outline">
        <div class="card-header">
            <div class="card-title">
                <h4><i class="fa fa-briefcase"></i> Übersicht</h4>
                <p>Verfügbare Produkte</p>
            </div>

            <div class="card-tools">
                <button type="button" class="mr-1 btn btn-default btn-sm" @click="$parent.loadProduct">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
                <!-- <b-button v-if="!editMode" size="sm" variant="primary" @click="editModeOn">Bearbeiten</b-button>
                <b-button v-if="editMode" size="sm" variant="default" @click="editModeOff" class="mr-1">Abbrechen</b-button> -->
                <!-- <b-button v-if="editMode" size="sm" variant="success" @click="editProduct">Speichern</b-button> -->
                <b-button size="sm" variant="success" @click="editProduct">Speichern</b-button>
            </div>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="col-md-8">
                    <!-- <div class="row" v-if="this.product.article == null">
                        <div class="text-right col-md-12">
                            <b-button size="sm" @click="newModal" variant="primary" class="mr-1" v-if="$auth.check('articles.create')">Neuer Artikel</b-button>
                            <b-button size="sm" @click="assignModal" variant="primary" class="text-right" v-if="$auth.check('articles.index')">Vorh. Artikel</b-button>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" name="name" id="name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="logo" class="control-label">Produktbild</label>
                                <b-form-file id="logo" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}"/>
                                <has-error :form="form" field="logo"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="label_front_id" class="control-label">Etikett Vorderseite</label>
                                <select v-model="form.label_front_id" name="label_front_id" id="label_front_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('label_front_id')}">
                                    <option :value="null">--Kein Etikett--</option>
                                    <option v-for="label in labels" :key="label.id" :value="label.id">{{label.name}}</option>
                                </select>
                                <has-error :form="form" field="label_front_id"></has-error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="label_back_id" class="control-label">Etikett Rückseite</label>
                                <select v-model="form.label_back_id" name="label_back_id" id="label_back_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('label_back_id')}">
                                    <option :value="null">--Kein Etikett--</option>
                                    <option v-for="label in labels" :key="label.id" :value="label.id">{{label.name}}</option>
                                </select>
                                <has-error :form="form" field="label_back_id"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="label_additional_first_id" class="control-label">Zus. Etikett 1</label>
                                <select v-model="form.label_additional_first_id" name="label_additional_first_id" id="label_additional_first_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('label_additional_first_id')}">
                                    <option :value="null">--Kein Etikett--</option>
                                    <option v-for="label in labels" :key="label.id" :value="label.id">{{label.name}}</option>
                                </select>
                                <has-error :form="form" field="label_additional_first_id"></has-error>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="label_additional_second_id" class="control-label">Zus. Etikett 2</label>
                                <select v-model="form.label_additional_second_id" name="label_additional_second_id" id="label_additional_second_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('label_additional_second_id')}">
                                    <option :value="null">--Kein Etikett--</option>
                                    <option v-for="label in labels" :key="label.id" :value="label.id">{{label.name}}</option>
                                </select>
                                <has-error :form="form" field="label_additional_second_id"></has-error>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <label for="duration" class="control-label">Arbeitsdauer (Minuten)*</label>
                            <input v-model="form.duration" name="duration" id="duration" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('duration')}">
                            <has-error :form="form" field="duration"></has-error>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="notice" class="control-label">Notizen</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice"></ckeditor>
                                <has-error :form="form" field="description"></has-error>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="recipe" class="control-label">Herstellung</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.recipe"></ckeditor>
                                <has-error :form="form" field="recipe"></has-error>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-4" v-if="product.thumbnail != ''" style="text-align: center;">
                    <figure class="float-right mt-2" style="width: 100%; height: auto;">
                        <img v-bind:src="product.thumbnail" id="brandLogo" class="img-fluid" alt="img">
                    </figure>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: "General",

    props: [
        'product',
        'units',
        'labels'
    ],

    data() {
        return {
            form: new window.Form({
                id: "",
                name: "",
                label_front_id: null,
                label_back_id: null,
                label_additional_first_id: null,
                label_additional_second_id: null,
                recipe: "",
                notice: "",
                thumbnail: "",
                duration: "",
                _method: "PUT",
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            modalTitle: "Neue Ware erfassen",
            editMode: false,
        }
    },

    watch: {
        product: {
            handler () {
                if(this.product.notice == null)
                {
                    this.product.notice = "";
                }
                if(this.product.recipe == null)
                {
                    this.product.recipe = "";
                }
                this.form.fill(this.product);
                this.form._method = "PUT";
            },
            deep: true
        },
    },

    methods:{
        editProduct() {
            this.form
                .post("/products/"+this.product.id)
                .then(() => {
                    this.$parent.loadProduct();
                    this.editModeOff();
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        editModeOn() {
            this.editMode = true;
        },

        editModeOff() {
            this.form.reset();
            this.form.fill(this.product);
            this.form._method ="PUT";
            this.editMode = false;
        },
    },

    created(){
        if(this.product.notice == null)
        {
            this.product.notice = "";
        }
        if(this.product.recipe == null)
        {
            this.product.recipe = "";
        }
        this.form.fill(this.product);
        this.form._method = "PUT";
    }

}
</script>

<style>

</style>